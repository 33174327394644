import React from 'react';
import ChartWrapper from '../ChartWrapper';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { setSelectedFilters } from '../../../../redux/actions/sliceActions';
// import { titleCaseToSnakeCase, formatDateToGMT, getDates, formatSourceDisplayKeyShortForm } from '../../../../services/utils/Helper';
// import dayjs from 'dayjs';

export default function DonutChart(props) {
    const { google, chartData, pieHole, sliceColors, chartArea, width, height = '', type = '', enableInteractivity = true, events = false, totalCount = '',
        company, distributor, source, timeRange, finalCustomDate,
    } = props;
    // const navigate = useNavigate();
    // const dispatch = useDispatch();

    // get start and end dates for dispatching as per timeRange 
    // const getGMTDates = (timeRange) => {
    //     if (timeRange !== 'custom') {
    //         return {
    //             startDateGMT: dayjs(formatDateToGMT(getDates(timeRange)?.startDate)),
    //             endDateGMT: dayjs(new Date())
    //         }
    //     }
    //     return {
    //         startDateGMT: finalCustomDate.start_date,
    //         endDateGMT: finalCustomDate.end_date
    //     }
    // }

    // const handleSliceClick = (slice) => {
    //     dispatch(setSelectedFilters(type, type ==='status' ? titleCaseToSnakeCase(slice) : formatSourceDisplayKeyShortForm(slice), company === 'All' ? { key: 'All', display_key: 'All' } : company, distributor === 'All' ? { key: 'All', display_key: 'All' } : distributor, source, getGMTDates(timeRange)?.startDateGMT, getGMTDates(timeRange)?.endDateGMT));
    //     navigate('/home/file-submissions');
    // }


    return (
        <div id='donut-chart'>
            <ChartWrapper
                google={google}
                chartType="PieChart"
                options={{
                    title: '',
                    legend: 'none',
                    tooltip: { isHtml: true },
                    sliceVisibilityThreshold: 0,
                    pieHole: pieHole,
                    pieSliceText: 'none',
                    colors: sliceColors,
                    width: width,
                    height: height,
                    chartArea: chartArea,
                    enableInteractivity: enableInteractivity,
                }}
                // onSliceClick={type && handleSliceClick}     // if type present then only slice click works
                chartData={chartData}
            />
            {events && <div className='h3Medium' style={{ textAlign: 'center' }}>Total Events : <span style={{fontWeight: '600'}}>{totalCount}</span></div>}
        </div>
    )
}