import logo from './logo.svg';
import './App.css';
import React from 'react';
import Dashboard from './views/dashboard/Dashboard'
import Navbar from './views/navbar/Navbar';
import { Navigate, Route, Routes } from 'react-router-dom';

function App() {

  return (
    <div style={{ height: '100vh', overflowY: 'auto' }}>
      <div className="App-container" id="outer-container" style={{ position: "sticky", top: '0', zIndex: '4' }} >
        <Navbar />
      </div>

      <Routes>
        <Route path={"/dashboard"} element={<Dashboard />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </div>
  );
}

export default App;
