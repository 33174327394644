import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
} from "@mui/material";
import pharmaracklogoNew from "../../components/assets/dashboard/pharmarack-logo.png";




const Navbar = () => {
  const [value, setvalue] = useState([]);

  return (
    <Box>
      <AppBar sx={{ position: "sticky", height: "70px" }} className='gradientBackground' >
        <Toolbar>
          <Box padding={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={pharmaracklogoNew}
              alt="logo"
              width='66px'
              height='40px'
            />
            <span style={{ color: '#4591d5', fontWeight: '700', fontSize: '1.4rem', marginBottom: '0.2rem' }}>pharmarack</span>
            <span style={{ fontWeight: '700', fontSize: '1.4rem', marginBottom: '0.2rem', borderRight: '3px solid #FAAD2F', padding: '1rem 0', marginRight: '0.4rem', marginLeft: '0.4rem' }}></span>
            <span style={{ color: 'orange', fontWeight: '700', fontSize: '1.4rem', marginBottom: '0.2rem' }}>Territory Health Index</span>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>

  );
};

export default Navbar;
